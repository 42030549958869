import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import * as SecureLS from "secure-ls";
import { MatSnackBar } from "@angular/material";
import { GlobalVariable } from "src/app/config/global";
import { Donor } from "src/app/model/donor";
import { DonorService } from "src/app/service/donor.service";
import { DnitypeService } from "src/app/service/dnitype.service";
import { DNIType } from "src/app/model/dniType";
import { UnionService } from "src/app/service/union.service";
import { BusinessunitService } from "src/app/service/businessunit.service";
import { ChurchService } from "src/app/service/church.service";
import { DistrictService } from "src/app/service/district.service";
import { BusinessUnit } from "src/app/model/businessUnit";
import { Church } from "src/app/model/church";
import { District } from "src/app/model/district";
import { Union } from "src/app/model/union";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  AppName = GlobalVariable.APP_NAME;
  isLoading = false;
  errorMessage = "";
  signupForm: FormGroup;

  // model
  dniTypes: DNIType[];
  selectedDNIType = <DNIType>{
    id: "",
  };
  unions: Union[] = [];
  selectedUnion = <Union>{
    id: "",
  };
  businessUnits: BusinessUnit[] = [];
  selectedBusinessUnit = <BusinessUnit>{
    id: "",
  };
  districts: District[];
  selectedDistrict = <District>{
    id: "",
  };
  churchs: Church[];
  selectedChurch = <Church>{
    id: "",
  };
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private dnitypeService: DnitypeService,
    private unionService: UnionService,
    private businessunitService: BusinessunitService,
    private districtService: DistrictService,
    private churchService: ChurchService,
    private donorService: DonorService
  ) {}

  ngOnInit() {
    this.initForms();
    this.getDNITypes();
    this.getAllUnion();
  }

  initForms() {
    this.signupForm = this.formBuilder.group(
      {
        email: new FormControl("", [Validators.required, Validators.email]),
        password: new FormControl("", [
          Validators.required,
          Validators.pattern("\\s*(\\S\\s*){6,}"),
        ]),
        repassword: new FormControl("", [
          Validators.required,
          Validators.pattern("\\s*(\\S\\s*){6,}"),
        ]),
        dniType: new FormControl("", [Validators.required]),
        dni: new FormControl("", [
          Validators.required,
          Validators.pattern("\\s*(\\S\\s*){6,}"),
        ]),
        firstName: new FormControl("", [Validators.required]),
        middleName: new FormControl(""),
        lastName: new FormControl("", [Validators.required]),
        secondLastName: new FormControl(""),
        orgName: new FormControl("", [Validators.required]),
        address: new FormControl("", [Validators.required]),
        phoneNumber: new FormControl("", [
          Validators.required,
          Validators.pattern("\\s*(\\S\\s*){7,}"),
        ]),
        taxFiler: new FormControl("", [Validators.required]),
        certificate: new FormControl("", [Validators.required]),
        union: new FormControl("", [Validators.required]),
        bu: new FormControl("", [Validators.required]),
        district: new FormControl("", [Validators.required]),
        church: new FormControl("", [Validators.required]),
        privacy: new FormControl(false, [Validators.requiredTrue]),
      },
      { validator: this.ConfirmedValidator("password", "repassword") }
    );
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  getErrorMessage(component: string) {
    let errorMessage = "";
    switch (component) {
      case "email":
        errorMessage = this.signupForm.get("email").hasError("required")
          ? "campo requerido"
          : this.signupForm.get("email").hasError("email")
          ? "ingresa un correo válido"
          : "";
        break;
      case "password":
        errorMessage = this.signupForm.get("password").hasError("required")
          ? "debe ingresar su contraseña"
          : this.signupForm.get("password").hasError("pattern")
          ? "mínimo 6 caracteres"
          : "";
        break;
      case "repassword":
        errorMessage = this.signupForm.get("repassword").hasError("required")
          ? "debe confirmar su contraseña"
          : this.signupForm.get("repassword").hasError("pattern")
          ? "mínimo 6 caracteres"
          : "";
        break;
      case "dni":
        errorMessage = this.signupForm.get("dni").hasError("required")
          ? "debe ingresar su número de identificación"
          : this.signupForm.get("dni").hasError("pattern")
          ? "mínimo 6 caracteres"
          : "";
        break;
      case "dniType":
        errorMessage = this.signupForm.get("dniType").hasError("required")
          ? "debe seleccionar un tipo de documento"
          : "";
        break;
      case "phoneNumber":
        errorMessage = this.signupForm.get("phoneNumber").hasError("required")
          ? "debe ingresar un número de teléfono"
          : this.signupForm.get("phoneNumber").hasError("pattern")
          ? "mínimo 7 caracteres"
          : "";
        break;
      case "taxFiler":
        errorMessage = this.signupForm.get("taxFiler").hasError("required")
          ? "debe seleccionar una opción"
          : "";
        break;
      case "certificate":
        errorMessage = this.signupForm.get("certificate").hasError("required")
          ? "debe seleccionar una opción"
          : "";
        break;
      case "union":
        errorMessage = this.signupForm.get("union").hasError("required")
          ? "debe seleccionar su unión"
          : "";
        break;
      case "bu":
        errorMessage = this.signupForm.get("bu").hasError("required")
          ? "debe seleccionar su campo"
          : "";
        break;
      case "district":
        errorMessage = this.signupForm.get("district").hasError("required")
          ? "debe seleccionar su distrito"
          : "";
        break;
      case "church":
        errorMessage = this.signupForm.get("church").hasError("required")
          ? "debe seleccionar su iglesia"
          : "";
        break;
      case "privacy":
        errorMessage = this.signupForm.get("privacy").hasError("required")
          ? "Debe aceptar términos y condiciones para poder continuar"
          : "";
        break;
    }
    return errorMessage;
  }

  getDNITypes() {
    this.isLoading = true;
    this.dnitypeService.get().subscribe(
      (p) => {
        this.dniTypes = p.results !== undefined ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.dniTypes.length > 0) {
        }
      }
    );
  }

  /** Event handler when district select component changes */
  dniTypesSelectionChange(event: { value: DNIType }) {
    this.selectedDNIType = event.value;
    if (this.selectedDNIType.id !== "31") {
      this.signupForm.controls["orgName"].setValidators([]);
      this.signupForm.controls["orgName"].updateValueAndValidity();
      this.signupForm.controls["firstName"].setValidators([
        Validators.required,
      ]);
      this.signupForm.controls["firstName"].updateValueAndValidity();
      this.signupForm.controls["lastName"].setValidators([Validators.required]);
      this.signupForm.controls["lastName"].updateValueAndValidity();
    } else {
      this.signupForm.controls["firstName"].setValidators([]);
      this.signupForm.controls["firstName"].updateValueAndValidity();
      this.signupForm.controls["lastName"].setValidators([]);
      this.signupForm.controls["lastName"].updateValueAndValidity();
      this.signupForm.controls["orgName"].setValidators([Validators.required]);
      this.signupForm.controls["orgName"].updateValueAndValidity();
    }
  }

  getAllUnion() {
    this.isLoading = true;
    this.unionService.get().subscribe(
      (p) => {
        this.unions = p.results !== undefined ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.unions.length > 0) {
        }
      }
    );
  }

  /** Event handler when business unit select component changes */
  unionSelectionChange(event: { value: Union }) {
    this.selectedUnion = event.value;
    this.signupForm.controls["bu"].setValue(null);
    this.signupForm.controls["district"].setValue(null);
    this.signupForm.controls["church"].setValue(null);
    this.businessUnits = [];
    this.districts = [];
    this.churchs = [];
    this.getBusinessUnitByUnion();
  }

  getBusinessUnitByUnion() {
    this.isLoading = true;
    this.businessunitService.getByUnion(this.selectedUnion.id).subscribe(
      (p) => {
        this.businessUnits = p.results !== undefined ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.businessUnits.length > 0) {
        }
      }
    );
  }

  /** Event handler when business unit select component changes */
  buSelectionChange(event: { value: BusinessUnit }) {
    this.selectedBusinessUnit = event.value;
    this.signupForm.controls["district"].setValue(null);
    this.signupForm.controls["church"].setValue(null);
    this.districts = [];
    this.churchs = [];
    this.getDistricsByBU();
  }

  getDistricsByBU() {
    this.isLoading = true;
    this.districtService.getByBU(this.selectedBusinessUnit.id).subscribe(
      (p) => {
        this.districts = p.results !== undefined ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.districts.length > 0) {
        }
      }
    );
  }

  /** Event handler when district select component changes */
  districtSelectionChange(event: { value: District }) {
    this.selectedDistrict = event.value;
    this.signupForm.controls["church"].setValue(null);
    this.churchs = [];
    this.getChurchsByDistrict();
  }

  getChurchsByDistrict() {
    this.isLoading = true;
    this.churchService.getByDistrict(this.selectedDistrict.id).subscribe(
      (p) => {
        this.churchs = p.results !== undefined ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.churchs.length > 0) {
        }
      }
    );
  }

  /** Event handler when church select component changes */
  churchSelectionChange(event: { value: Church }) {
    this.selectedChurch = event.value;
  }

  createDonor(form: FormGroup) {
    let message = "";
    const submission = this.buildJSONToPostDonor();
    this.donorService.post(submission).subscribe(
      (p) => (message = p.message),
      (e) =>
        this.launchMessage(
          "El registro no se pudo procesar en estos momentos. Por favor intente nuevamente"
        ),
      () => {
        this.isLoading = false;
        if (message === "Donor was created") {
          this.launchMessage("Registro realizado con éxito.");
          this.router.navigate(["/validatecode"]);
        } else if (message === "Donor exists") {
          this.launchMessage(
            "Ya existe un usuario con este correo o número de documento. Por favor intente con uno diferente"
          );
        } else {
          this.launchMessage(
            "El registro no se pudo procesar en estos momentos. Por favor intente nuevamente"
          );
        }
      }
    );
  }

  /** this method builds the json using the data enter in the form and selected assets */
  buildJSONToPostDonor(): any {
    const x = {
      id: this.signupForm.value.dni,
      dni_type: this.signupForm.value.dniType.id,
      email: this.signupForm.value.email,
      password: encodeURIComponent(window.btoa(this.signupForm.value.password)),
      firstname: this.signupForm.value.firstName,
      middlename: this.signupForm.value.middleName,
      first_lastname: this.signupForm.value.lastName,
      second_lastname: this.signupForm.value.secondLastName,
      org_name: this.signupForm.value.orgName,
      address: this.signupForm.value.address,
      cellphone: this.signupForm.value.phoneNumber,
      tax_filer: this.signupForm.value.taxFiler,
      certificate: this.signupForm.value.certificate,
      union_code: this.selectedUnion.id,
      bu_code: this.selectedBusinessUnit.id,
      district_id: this.selectedDistrict.id,
      church_id: this.selectedChurch.id,
    };
    return x;
  }

  /** Launch message of the snackBar component */
  launchMessage(message: string) {
    this.errorMessage = "";
    this.isLoading = false;
    const action = "OK";
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
