import { Injectable } from "@angular/core"
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http"
import { Observable, of } from "rxjs"
import { catchError } from "rxjs/operators"
import { GlobalVariable } from "../config/global"

@Injectable({
  providedIn: "root",
})
export class WompiService {
  constructor(private http: HttpClient) {}

  get(reference: string): Observable<any> {
    return this.http
      .get(buildGetUrl(GlobalVariable.WOMPI_READPAYMENT_URL, reference), {
        headers: this.getHeadersNA(),
      })
      .pipe(catchError((error) => of(`Bad Promise: ${error}`)))
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return headers
  }
}

function buildGetUrl(type: string, reference: string): string {
  let finalUrl = GlobalVariable.WOMPI_API_URL
  finalUrl += type
  finalUrl += reference
  return finalUrl
}
