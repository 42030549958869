import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { GlobalVariable } from "../config/global";

@Injectable({
  providedIn: "root",
})
export class DnitypeService {
  constructor(private http: HttpClient) {}

  get(): Observable<any> {
    return this.http.get(buildGetUrl(GlobalVariable.DNITYPE_READ_EP), {
      headers: this.getHeadersNA(),
    });
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders();
    headers.set("Accept", "application/json");
    return headers;
  }
}

function buildGetUrl(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  return finalUrl;
}
