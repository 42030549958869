import { Component, OnInit, EventEmitter} from '@angular/core';
import { MatSnackBar, MatDialogRef } from "@angular/material"
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from "@angular/forms"
import { ActivatedRoute } from "@angular/router"
import { Church } from 'src/app/model/church';
import { ChurchService } from "src/app/service/church.service"

@Component({
  selector: 'app-church-search',
  templateUrl: './church-search.component.html',
  styleUrls: ['./church-search.component.scss']
})
export class ChurchSearchComponent implements OnInit {
  data = new EventEmitter();
  errorMessage = ""
  isLoading = false
  selectedChurchForm = new FormControl();
  //Params
  churchParameter
  //Model   
  churchs: Church[] = [
  ];
  
  filteredOptions: Observable<Church[]>;

  options=[]

  constructor(
    public dialogRef: MatDialogRef<ChurchSearchComponent>,
    private route: ActivatedRoute,
    private churchService: ChurchService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.loadData()
    this.filtered()
  }

  loadData(){
    this.isLoading = true
    if(JSON.parse(sessionStorage.getItem('Churchs')))
    {
      this.churchs = JSON.parse(sessionStorage.getItem('Churchs'))
      this.isLoading = false
    }
    else 
    {
      this.churchService.get().subscribe(
        (p) => {
          this.churchs = p.results !== undefined ? p.results : []
          sessionStorage.setItem('Churchs', JSON.stringify(this.churchs))
        },
        (e) => this.launchMessage(e),
        () => {
          this.isLoading = false
        }
      )
    }
  }

  displayFn(user: Church): string {
    return user && user.name ? user.name : '';
  }
  
  filtered() {
    this.filteredOptions = this.selectedChurchForm.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.churchs.slice()) 
      );
  }

  private _filter(value: string): Church[] {
    const filterValue = value.toLowerCase();
    return this.churchs.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  selected(){
    this.data.emit(this.selectedChurchForm.value)
  }

/* Launch message of the snackBar component  */
    launchMessage(message: string) {
     this.errorMessage = ""
     this.isLoading = false
     const action = "OK"
     this.snackBar.open(message, action, {
       duration: 5000,
     })
   } 

}
