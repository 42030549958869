import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { GlobalVariable } from "../config/global";

@Injectable({
  providedIn: "root",
})
export class BusinessunitService {
  constructor(private http: HttpClient) {}

  get(): Observable<any> {
    return this.http.get(buildGetUrl(GlobalVariable.BUSINESSUNIT_READ_EP), {
      headers: this.getHeadersNA(),
    });
  }

  getOne(id: string): Observable<any> {
    return this.http.get(
      buildGetBy(GlobalVariable.BUSINESSUNIT_READONE_EP, id),
      {
        headers: this.getHeadersNA(),
      }
    );
  }

  getByUnion(id: string): Observable<any> {
    return this.http.get(
      buildGetBy(GlobalVariable.BUSINESSUNIT_READBYUNION_EP, id),
      {
        headers: this.getHeadersNA(),
      }
    );
  }

  /* attached file */
  uploadFile(selectedFile) {
    let submission = JSON.stringify(selectedFile);
    return this.http.post(
      buildPOSTUrl(GlobalVariable.BUSINESSUNIT_ATTACHFILE),
      submission
    );
  }

  sendEmailSupport(data: any): Observable<any> {
    return this.http.post(
      buildGetData(GlobalVariable.BUSINESSUNIT_SENDEMAILSUPPORT),
      data,
      {
        headers: this.getHeadersNA(),
      }
    );
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders();
    headers.set("Accept", "application/json");
    return headers;
  }
}

function buildGetUrl(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  return finalUrl;
}

function buildGetBy(type: string, id: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  finalUrl += "?id=" + id;
  return finalUrl;
}

function buildGetData(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  return finalUrl;
}

function buildPOSTUrl(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  return finalUrl;
}
