import { Component, OnInit } from "@angular/core";
import { GlobalVariable } from "src/app/config/global";
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  animations: [
    trigger("EnterLeave", [
      state("flyIn", style({ transform: "translateY(0%)" })),
      transition(":enter", [
        style({ transform: "translateY(100%)" }),
        animate("0.5s 300ms ease-in"),
      ]),
      transition(":leave", [
        animate("0.3s ease-out", style({ transform: "translateX(100%)" })),
      ]),
    ]),
    trigger("EnterTopLeave", [
      state("flyIn", style({ transform: "translateY(0%)" })),
      transition(":enter", [
        style({ transform: "translateY(-200%)" }),
        animate("0.5s 300ms ease-in"),
      ]),
      transition(":leave", [
        animate("0.3s ease-out", style({ transform: "translateX(100%)" })),
      ]),
    ]),
    trigger("EnterLeaveX", [
      state("flyIn", style({ transform: "translateX(0%)" })),
      transition(":enter", [
        style({ transform: "translateX(-200%)" }),
        animate("0.5s 300ms ease-in"),
      ]),
      transition(":leave", [
        animate("0.3s ease-out", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  AppName = GlobalVariable.APP_NAME;

  constructor(private router: Router) {}

  ngOnInit() {
    if (this.validateSession()) {
      this.router.navigate(["/myenvelope"]);
    }
  }

  validateSession(): boolean {
    if (localStorage.getItem("auid") === null) {
      return false;
    } else {
      return true;
    }
  }

  play() {
    let audio = <HTMLMediaElement>document.getElementById("player");
    audio.src = "https://alfoliadventista.org/assets/522pista.mp3";
    audio.load();
    audio.play();
  }
}
