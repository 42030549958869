import { Injectable } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Observable } from "rxjs"
import { GlobalVariable } from "../config/global"

@Injectable({
  providedIn: "root"
})
export class EpaycoService {
  constructor(private http: HttpClient) {}

  get(reference: string): Observable<any> {
    return this.http.get(
      buildGetUrl(GlobalVariable.EPAYCO_READPAYMENT_URL, reference),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return headers
  }
}

function buildGetUrl(type: string, reference: string): string {
  let finalUrl = GlobalVariable.EPAYCO_API_URL
  finalUrl += type
  finalUrl += reference
  return finalUrl
}
