import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import * as SecureLS from "secure-ls";
import { GlobalVariable } from "src/app/config/global";
import { BusinessUnit } from "src/app/model/businessUnit";
import { Church } from "src/app/model/church";
import { District } from "src/app/model/district";
import { Donor } from "src/app/model/donor";
import { Union } from "src/app/model/union";
import { BusinessunitService } from "src/app/service/businessunit.service";
import { ChurchService } from "src/app/service/church.service";
import { DistrictService } from "src/app/service/district.service";
import { DonorService } from "src/app/service/donor.service";
import { UnionService } from "src/app/service/union.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  AppName = "";
  isLoading = false;
  errorMessage = "";
  ls: SecureLS;
  churchForm: FormGroup;
  passwordForm: FormGroup;
  // donor information
  donor: Donor;
  // params
  unionParameter;
  businessUnitParameter;
  districtParameter;
  churchParameter;
  // model
  availableYears: string[] = [];
  selectedYear = "";
  availableBusinessUnits: any[] = [];
  selectedBU = "";
  selectedBusinessUnitCertificate = <BusinessUnit>{
    id: "",
  };
  unions: Union[] = [];
  selectedUnion = <Union>{
    id: "",
  };
  businessUnits: BusinessUnit[] = [];
  selectedBusinessUnit = <BusinessUnit>{
    id: "",
  };
  districts: District[];
  selectedDistrict = <District>{
    id: "",
  };
  churchs: Church[];
  selectedChurch = <Church>{
    id: "",
  };

  // Report information
  letterTitle = "";
  letterOne = "";
  letterTwo = "";
  letterThree = "";
  letterFour = "";
  letterFive = "";
  letterSix = "";
  letterArticles = "";
  letterDate = "";

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private donorService: DonorService,
    private unionService: UnionService,
    private businessunitService: BusinessunitService,
    private districtService: DistrictService,
    private churchService: ChurchService
  ) {}

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME;
    this.ls = new SecureLS({ encodingType: "aes" });
    this.initForms();
    if (!this.validateSession()) {
      this.router.navigate(["/signin"]);
      return;
    } else {
      this.getDonorData();
    }
  }

  validateSession(): boolean {
    if (localStorage.getItem("auid") === null) {
      return false;
    } else {
      return true;
    }
  }

  initForms() {
    this.churchForm = this.formBuilder.group({
      union: new FormControl("", [Validators.required]),
      bu: new FormControl("", [Validators.required]),
      district: new FormControl("", [Validators.required]),
      church: new FormControl("", [Validators.required]),
    });
    this.passwordForm = this.formBuilder.group(
      {
        password: new FormControl("", [
          Validators.required,
          Validators.pattern("\\s*(\\S\\s*){6,}"),
        ]),
        repassword: new FormControl("", [
          Validators.required,
          Validators.pattern("\\s*(\\S\\s*){6,}"),
        ]),
      },
      { validator: this.ConfirmedValidator("password", "repassword") }
    );
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  getErrorMessage(component: string) {
    let errorMessage = "";
    switch (component) {
      case "union":
        errorMessage = this.churchForm.get("union").hasError("required")
          ? "debe seleccionar su unión"
          : "";
        break;
      case "bu":
        errorMessage = this.churchForm.get("bu").hasError("required")
          ? "debe seleccionar su campo"
          : "";
        break;
      case "district":
        errorMessage = this.churchForm.get("district").hasError("required")
          ? "debe seleccionar su distrito"
          : "";
        break;
      case "church":
        errorMessage = this.churchForm.get("church").hasError("required")
          ? "debe seleccionar su iglesia"
          : "";
        break;
      case "password":
        errorMessage = this.passwordForm.get("password").hasError("required")
          ? "debe ingresar su contraseña"
          : this.passwordForm.get("password").hasError("pattern")
          ? "mínimo 6 caracteres"
          : "";
        break;
      case "repassword":
        errorMessage = this.passwordForm.get("repassword").hasError("required")
          ? "debe confirmar su contraseña"
          : this.passwordForm.get("repassword").hasError("pattern")
          ? "mínimo 6 caracteres"
          : "";
        break;
    }
    return errorMessage;
  }

  /** function to get the current logged in user*/
  getDonorData() {
    this.donor = <Donor>{
      id: "",
    };
    const userid = this.ls.get("auid");
    this.donorService.getDonor(userid).subscribe(
      (p) => {
        this.donor = p.results !== "" ? p.results[0] : null;
      },
      (e) => this.launchMessage(e),
      () => {
        if (this.donor) {
          //set donor church
          this.setDonorChurchParams();
          this.getAllUnion();
          //set donor values
          // this.churchForm.controls["firstName"].setValue(
          //   this.donor.firstname + " " + this.donor.middlename
          // );
          // this.churchForm.controls["lastName"].setValue(
          //   this.donor.first_lastname + " " + this.donor.second_lastname
          // );
          // this.churchForm.controls["email"].setValue(this.donor.email);
          // this.churchForm.controls["document"].setValue(this.donor.id);
          // this.churchForm.controls["phoneNumber"].setValue(
          //   this.donor.cellphone
          // );
        }
      }
    );
  }

  setDonorChurchParams() {
    this.unionParameter = this.donor.union_code;
    this.businessUnitParameter = this.donor.bu_code;
    this.districtParameter = this.donor.district_id;
    this.churchParameter = this.donor.church_id;
  }

  setPredefinedParams(type: string) {
    switch (type) {
      case "union":
        this.selectedUnion = this.unions.find(
          (u) => u.id.trim() === this.unionParameter
        );
        if (this.selectedUnion) {
          this.churchForm.controls["union"].setValue(this.selectedUnion);
          this.churchForm.controls["union"].enable();
          this.getBusinessUnitByUnion();
        }
        break;
      case "businessunit":
        this.selectedBusinessUnit = this.businessUnits.find(
          (bu) => bu.id.trim() === this.businessUnitParameter
        );
        if (this.selectedBusinessUnit) {
          this.churchForm.controls["bu"].setValue(this.selectedBusinessUnit);
          this.churchForm.controls["bu"].enable();
          this.getDistricsByBU();
        }
        break;
      case "district":
        this.selectedDistrict = this.districts.find(
          (d) => d.id.trim() === this.districtParameter
        );
        if (this.selectedDistrict) {
          this.churchForm.controls["district"].setValue(this.selectedDistrict);
          this.churchForm.controls["district"].enable();
          this.getChurchsByDistrict();
        }
        break;
      case "church":
        this.selectedChurch = this.churchs.find(
          (c) => c.id.trim() === this.churchParameter
        );
        if (this.selectedChurch) {
          this.churchForm.controls["church"].setValue(this.selectedChurch);
          this.churchForm.controls["church"].enable();
        }
        break;
      default:
        break;
    }
  }

  getAllUnion() {
    this.isLoading = true;
    this.unionService.get().subscribe(
      (p) => {
        this.unions = p.results !== "" ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.unions.length > 0 && this.unionParameter) {
          this.setPredefinedParams("union");
          this.unionParameter = null;
        }
      }
    );
  }

  /** Event handler when business unit select component changes */
  unionSelectionChange(event: { value: Union }) {
    this.selectedUnion = event.value;
    this.churchForm.controls["bu"].setValue(null);
    this.churchForm.controls["district"].setValue(null);
    this.churchForm.controls["church"].setValue(null);
    this.businessUnits = [];
    this.districts = [];
    this.churchs = [];
    this.getBusinessUnitByUnion();
  }

  getBusinessUnitByUnion() {
    this.isLoading = true;
    this.businessunitService.getByUnion(this.selectedUnion.id).subscribe(
      (p) => {
        this.businessUnits = p.results !== "" ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.businessUnits.length > 0 && this.businessUnitParameter) {
          this.setPredefinedParams("businessunit");
          this.businessUnitParameter = null;
        }
      }
    );
  }

  /** Event handler when business unit select component changes */
  buSelectionChange(event: { value: BusinessUnit }) {
    this.selectedBusinessUnit = event.value;
    this.churchForm.controls["district"].setValue(null);
    this.churchForm.controls["church"].setValue(null);
    this.districts = [];
    this.churchs = [];
    this.getDistricsByBU();
  }

  getDistricsByBU() {
    this.isLoading = true;
    this.districtService.getByBU(this.selectedBusinessUnit.id).subscribe(
      (p) => {
        this.districts = p.results !== "" ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.districts.length > 0 && this.districtParameter) {
          this.setPredefinedParams("district");
          this.districtParameter = null;
        }
      }
    );
  }

  /** Event handler when district select component changes */
  districtSelectionChange(event: { value: District }) {
    this.selectedDistrict = event.value;
    this.churchForm.controls["church"].setValue(null);
    this.churchs = [];
    this.getChurchsByDistrict();
  }

  getChurchsByDistrict() {
    this.isLoading = true;
    this.churchService.getByDistrict(this.selectedDistrict.id).subscribe(
      (p) => {
        this.churchs = p.results !== "" ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.churchs.length > 0 && this.churchParameter) {
          this.setPredefinedParams("church");
          this.churchParameter = null;
        }
      }
    );
  }

  /** Event handler when church select component changes */
  churchSelectionChange(event: { value: Church }) {
    this.selectedChurch = event.value;
  }

  updateChurch(form: FormGroup) {
    let message = "";
    const submission = this.buildJSONToPutDonorChurch();
    this.donorService.putUpdateChurch(submission).subscribe(
      (p) => (message = p.message),
      (e) =>
        this.launchMessage(
          "La actualización no se pudo procesar en estos momentos. Por favor intente nuevamente"
        ),
      () => {
        this.isLoading = false;
        if (message === "Updated") {
          this.launchMessage("Actualización realizada con éxito.");
        } else {
          this.launchMessage(
            "La actualización no se pudo procesar en estos momentos. Por favor intente nuevamente"
          );
        }
      }
    );
  }

  /** this method builds the json using the data enter in the form */
  buildJSONToPutDonorChurch(): any {
    const x = {
      id: this.donor.id,
      union_code: this.selectedUnion.id,
      bu_code: this.selectedBusinessUnit.id,
      district_id: this.selectedDistrict.id,
      church_id: this.selectedChurch.id,
    };
    return x;
  }

  updatePassword(form: FormGroup) {
    let message = "";
    const submission = this.buildJSONToPutDonorPassword();
    this.donorService.putUpdatePassword(submission).subscribe(
      (p) => (message = p.message),
      (e) =>
        this.launchMessage(
          "La actualización no se pudo procesar en estos momentos. Por favor intente nuevamente"
        ),
      () => {
        this.isLoading = false;
        if (message === "Updated") {
          this.launchMessage("Actualización realizada con éxito.");
          this.passwordForm.reset();
        } else {
          this.launchMessage(
            "La actualización no se pudo procesar en estos momentos. Por favor intente nuevamente"
          );
        }
      }
    );
  }

  /** this method builds the json using the data enter in the form */
  buildJSONToPutDonorPassword(): any {
    const x = {
      id: this.donor.id,
      password: encodeURIComponent(
        window.btoa(this.passwordForm.value.password)
      ),
    };
    return x;
  }

  /** Launch message of the snackBar component */
  launchMessage(message: string) {
    this.errorMessage = "";
    this.isLoading = false;
    const action = "OK";
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
