import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { GlobalVariable } from "../config/global";

@Injectable({
  providedIn: "root",
})
export class DonorService {
  constructor(private http: HttpClient) {}

  getValidateUser(email: string, password: string): Observable<any> {
    return this.http.get(
      buildValidateUsersUrl(GlobalVariable.DONOR_VALIDATE_EP, email, password),
      {
        headers: this.getHeadersNA(),
      }
    );
  }

  getValidateUserCode(code: string): Observable<any> {
    return this.http.get(
      buildValidateUserCodeUrl(GlobalVariable.DONOR_VALIDATECODE_EP, code),
      {
        headers: this.getHeadersNA(),
      }
    );
  }

  getPasswordReset(email: string): Observable<any> {
    return this.http.get(
      buildPasswordResetUrl(GlobalVariable.DONOR_PASSWORDRESET_EP, email),
      {
        headers: this.getHeadersNA(),
      }
    );
  }

  getDonor(id: string): Observable<any> {
    return this.http.get(buildDonorUrl(GlobalVariable.DONOR_READONE_EP, id), {
      headers: this.getHeadersNA(),
    });
  }

  post(submission: any): Observable<any> {
    return this.http
      .post(buildPOSTUrl(GlobalVariable.DONOR_CREATE_EP), submission, {
        headers: this.getHeadersNA(),
      })
      .pipe(catchError((error) => of(`Bad Promise: ${error}`)));
  }

  putUpdateChurch(submission: any): Observable<any> {
    return this.http
      .post(buildPOSTUrl(GlobalVariable.DONOR_UPDATE_CHURCH_EP), submission, {
        headers: this.getHeadersNA(),
      })
      .pipe(catchError((error) => of(`Bad Promise: ${error}`)));
  }

  putUpdatePassword(submission: any): Observable<any> {
    return this.http
      .post(buildPOSTUrl(GlobalVariable.DONOR_UPDATE_PASSWORD_EP), submission, {
        headers: this.getHeadersNA(),
      })
      .pipe(catchError((error) => of(`Bad Promise: ${error}`)));
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders();
    headers.set("Accept", "application/json");
    headers.set(
      "Access-Control-Allow-Methods",
      "GET, POST, DELETE, PUT, OPTIONS"
    );
    headers.set("Access-Control-Allow-Origin", "*");
    return headers;
  }
}

function buildValidateUsersUrl(type: string, e: string, p: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  finalUrl += "?e=" + e;
  finalUrl += "&p=" + encodeURIComponent(window.btoa(p));
  return finalUrl;
}

function buildValidateUserCodeUrl(type: string, c: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  finalUrl += "?c=" + c;
  return finalUrl;
}

function buildPasswordResetUrl(type: string, email: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  finalUrl += "?e=" + email;
  return finalUrl;
}

function buildDonorUrl(type: string, id: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  finalUrl += "?id=" + id;
  return finalUrl;
}

function buildPOSTUrl(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  return finalUrl;
}
