import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material"

@Component({
  selector: "app-confirmpayment-dialog",
  templateUrl: "./confirmpayment-dialog.component.html",
  styleUrls: ["./confirmpayment-dialog.component.scss"],
})
export class ConfirmpaymentDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmpaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public paymentInformation: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close()
  }
}
