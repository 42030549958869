import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxCurrencyModule } from "ngx-currency";
import { TooltipModule } from "ng2-tooltip-directive";
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from "@angular/material";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./view/home/home.component";
import { MyenvelopeComponent } from "./view/myenvelope/myenvelope.component";
import { TransactionresponseComponent } from "./view/transactionresponse/transactionresponse.component";
import { FooterComponent } from "./view/footer/footer.component";
import { TopmenuComponent } from "./view/topmenu/topmenu.component";
import { ConfirmpaymentDialogComponent } from "./view/confirmpayment-dialog/confirmpayment-dialog.component";
import { PaymentinfoDialogComponent } from "./view/paymentinfo-dialog/paymentinfo-dialog.component";
import { PaymentsecurityComponent } from "./view/paymentsecurity/paymentsecurity.component";
import { FaqComponent } from "./view/faq/faq.component";
import { ContactusComponent } from "./view/contactus/contactus.component";
import { ChurchSearchComponent } from "./view/church-search/church-search.component";
import { TopmenuHelpComponent } from "./view/topmenu-help/topmenu-help.component";
import { SignupComponent } from './view/signup/signup.component';
import { SigninComponent } from './view/signin/signin.component';
import { ProfileComponent } from './view/profile/profile.component';
import { PasswordresetComponent } from './view/passwordreset/passwordreset.component';
import { ValidatecodeComponent } from './view/validatecode/validatecode.component';
import { CertificateComponent } from './view/certificate/certificate.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MyenvelopeComponent,
    TransactionresponseComponent,
    FooterComponent,
    TopmenuComponent,
    ConfirmpaymentDialogComponent,
    PaymentinfoDialogComponent,
    PaymentsecurityComponent,
    FaqComponent,
    ContactusComponent,
    ChurchSearchComponent,
    TopmenuHelpComponent,
    SignupComponent,
    SigninComponent,
    ProfileComponent,
    PasswordresetComponent,
    ValidatecodeComponent,
    CertificateComponent,
  ],
  entryComponents: [
    ConfirmpaymentDialogComponent,
    PaymentinfoDialogComponent,
    ChurchSearchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxCurrencyModule,
    TooltipModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
