import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { GlobalVariable } from "src/app/config/global";
import { DonorService } from "src/app/service/donor.service";

@Component({
  selector: "app-passwordreset",
  templateUrl: "./passwordreset.component.html",
  styleUrls: ["./passwordreset.component.scss"],
})
export class PasswordresetComponent implements OnInit {
  isLoading = false;
  errorMessage = "";
  passwordResetForm: FormGroup;
  AppName = GlobalVariable.APP_NAME;

  constructor(
    private donorService: DonorService,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.initForms();
  }

  initForms() {
    this.passwordResetForm = this.formBuilder.group({
      email: new FormControl("", [Validators.required, Validators.email]),
    });
  }

  getErrorMessage(component: string) {
    let errorMessage = "";
    switch (component) {
      case "email":
        errorMessage = this.passwordResetForm.get("email").hasError("required")
          ? "campo requerido"
          : this.passwordResetForm.get("email").hasError("email")
          ? "ingresa un correo válido"
          : "";
        break;
    }
    return errorMessage;
  }

  recoverPassword(form: FormGroup) {
    this.isLoading = true;
    let response = "";
    this.donorService.getPasswordReset(form.value.email).subscribe(
      (p) => (response = p.message),
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        let message = "";
        if (response == "Email sent.") {
          message =
            "Se ha enviado un mensaje con instrucciones al correo electrónico. Si no ves el mensaje en la bandeja de entrada, verifica el correo no deseado.";
        } else if (response == "No donor.") {
          message =
            "El correo ingresado no está registrado en nuestro sistema.";
        } else {
          message = "Ha surgido un error, por favor intenta nuevamente.";
        }
        this.launchMessage(message);
      }
    );
  }

  /** Launch message of the snackBar component */
  launchMessage(message: string) {
    this.errorMessage = "";
    this.isLoading = false;
    const action = "OK";
    this.snackBar.open(message, action, {
      duration: 10000,
    });
  }
}
