import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router"
declare let gtag: Function
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "alfoli-fe"
  navigationtype
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag("config", "G-78NVE8L86C", {
          page_path: event.urlAfterRedirects,
        })
      }
    })
  }
  ngOnInit() {
    this.route.root.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("navigationtype") != false) {
        //do whatever you want
        this.navigationtype = params.navigationtype
        switch (this.navigationtype) {
          case "reviewrequest":
            this.navigationtype = this.router.navigate([
              "/reviewrequest/" + params.r + "/" + params.d,
            ])
            break
          case "home":
            this.router.navigate(["/home"])
            break
          default:
            this.router.navigate(["/login/" + params.authkey])
            break
        }
      }
    })
  }
}
