import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import * as SecureLS from "secure-ls";
import { MatDialog } from "@angular/material";
import { GlobalVariable } from "src/app/config/global";
import { ChurchSearchComponent } from "../church-search/church-search.component";
import { Donor } from "src/app/model/donor";
import { DonorService } from "src/app/service/donor.service";

@Component({
  selector: "app-topmenu",
  templateUrl: "./topmenu.component.html",
  styleUrls: ["./topmenu.component.scss"],
})
export class TopmenuComponent implements OnInit {
  AppName = "";
  userLoggedIn = false;
  ls: SecureLS;
  @Output() public readByChurch = new EventEmitter();
  // donor information
  donor: Donor;

  constructor(
    private router: Router,
    private donorService: DonorService,
    public dialog: MatDialog // public church: ChurchSearchComponent,
  ) {}

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME;
    if (this.validateSession()) {
      this.getDonorData();
    }
    this.userLoggedIn = this.validateSession() ? true : false;
  }

  validateSession(): boolean {
    if (localStorage.getItem("auid") === null) {
      return false;
    } else {
      return true;
    }
  }

  playPause() {
    let audio = <HTMLMediaElement>document.getElementById("player");
    if (audio.paused && audio.currentTime > 0 && !audio.ended) {
      audio.play();
    } else {
      audio.pause();
    }
  }

  /** function to get the current logged in user*/
  getDonorData() {
    this.donor = <Donor>{
      id: "",
    };
    this.ls = new SecureLS({ encodingType: "aes" });
    const userid = this.ls.get("auid");
    this.donorService.getDonor(userid).subscribe(
      (p) => {
        this.donor = p.results !== undefined ? p.results[0] : null;
      },
      (e) => e,
      () => {}
    );
  }

  public search = () => {
    this.readByChurch.emit();
  };

  /** click event to log out */
  logOut() {
    // DO SOMETHING
    window.localStorage.clear();
    this.router.navigate(["/home"]);
    // const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //   width: "350px",
    //   data: "¿Estás seguro que quieres cerrar sesión?",
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result) {
    //     // DO SOMETHING
    //     window.localStorage.clear();
    //     this.router.navigate(["/login"]);
    //   }
    // });
  }
}
