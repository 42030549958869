import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./view/home/home.component";
import { MyenvelopeComponent } from "./view/myenvelope/myenvelope.component";
import { TransactionresponseComponent } from "./view/transactionresponse/transactionresponse.component";
import { FaqComponent } from "./view/faq/faq.component";
import { SignupComponent } from "./view/signup/signup.component";
import { SigninComponent } from "./view/signin/signin.component";
import { PasswordresetComponent } from "./view/passwordreset/passwordreset.component";
import { ProfileComponent } from "./view/profile/profile.component";
import { ValidatecodeComponent } from "./view/validatecode/validatecode.component";
import { CertificateComponent } from "./view/certificate/certificate.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "signup", component: SignupComponent },
  { path: "signin", component: SigninComponent },
  { path: "validatecode", component: ValidatecodeComponent },
  { path: "validatecode/:c", component: ValidatecodeComponent },
  { path: "passwordreset", component: PasswordresetComponent },
  { path: "certificate", component: CertificateComponent },
  { path: "profile", component: ProfileComponent },
  { path: "help", component: FaqComponent },
  { path: "myenvelope", redirectTo: "myenvelope/", pathMatch: "full" },
  { path: "myenvelope/:u", component: MyenvelopeComponent },
  { path: "myenvelope/:u/:bu", component: MyenvelopeComponent },
  { path: "myenvelope/:u/:bu/:d", component: MyenvelopeComponent },
  { path: "myenvelope/:u/:bu/:d/:c", component: MyenvelopeComponent },
  { path: "transactionresponse", component: TransactionresponseComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
