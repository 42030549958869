import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import * as moment from "moment";
import jsPDF from "jspdf";
import * as SecureLS from "secure-ls";
import { GlobalVariable } from "src/app/config/global";
import { BusinessUnit } from "src/app/model/businessUnit";
import { DonationCertificate } from "src/app/model/donationCertificates";
import { Donor } from "src/app/model/donor";
import { BusinessunitService } from "src/app/service/businessunit.service";
import { DonationcertificateService } from "src/app/service/donationcertificate.service";
import { DonorService } from "src/app/service/donor.service";

@Component({
  selector: "app-certificate",
  templateUrl: "./certificate.component.html",
  styleUrls: ["./certificate.component.scss"],
})
export class CertificateComponent implements OnInit {
  AppName = "";
  isLoading = false;
  errorMessage = "";
  ls: SecureLS;
  myCertificateForm: FormGroup;
  // donor information
  donor: Donor;
  // model
  donationCertificates: DonationCertificate[] = [];
  selectedDonationCertificate = <DonationCertificate>{
    bu_code: "",
  };
  availableYears: string[] = [];
  selectedYear = "";
  availableBusinessUnits: any[] = [];
  selectedBU = "";
  selectedBusinessUnitCertificate = <BusinessUnit>{
    id: "",
  };
  businessUnits: BusinessUnit[] = [];

  // Report information
  letterTitle = "";
  letterOne = "";
  letterTwo = "";
  letterThree = "";
  letterFour = "";
  letterFive = "";
  letterSix = "";
  letterArticles = "";
  letterDate = "";

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private donorService: DonorService,
    private donationcertificateService: DonationcertificateService,
    private businessunitService: BusinessunitService
  ) {}

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME;
    this.ls = new SecureLS({ encodingType: "aes" });
    this.initForms();
    if (!this.validateSession()) {
      this.router.navigate(["/signin"]);
      return;
    } else {
      this.getDonorData();
    }
  }

  validateSession(): boolean {
    if (localStorage.getItem("auid") === null) {
      return false;
    } else {
      return true;
    }
  }

  initForms() {
    this.myCertificateForm = this.formBuilder.group({
      year: new FormControl("", [Validators.required]),
      businessUnit: new FormControl("", [Validators.required]),
    });
  }

  /** function to get the current logged in user*/
  getDonorData() {
    this.donor = <Donor>{
      id: "",
    };
    const userid = this.ls.get("auid");
    this.donorService.getDonor(userid).subscribe(
      (p) => {
        this.donor = p.results !== "" ? p.results[0] : null;
      },
      (e) => this.launchMessage(e),
      () => {
        if (this.donor) {
          //get certificates
          this.getMyCertificates();
        }
      }
    );
  }

  getMyCertificates() {
    this.isLoading = true;
    const userid = this.ls.get("auid");
    this.donationcertificateService.getMyCertificates(userid).subscribe(
      (p) => {
        this.donationCertificates = p.results !== "" ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.donationCertificates) {
          const foundYears = this.donationCertificates
            .filter((donationCertificate, index) => {
              return (
                this.donationCertificates.indexOf(donationCertificate) === index
              );
            })
            .map((donationCertificate) => {
              return donationCertificate.year;
            });
          this.availableYears = foundYears.filter((year, index) => {
            return foundYears.indexOf(year) === index;
          });
        }
      }
    );
  }

  /** Event handler when business unit select component changes */
  yearSelectionChange(event: { value: string }) {
    this.selectedYear = event.value;
    this.availableBusinessUnits = this.donationCertificates.filter(
      (donationCertificate) => {
        return donationCertificate.year === this.selectedYear;
      }
    );
  }

  /** Event handler when business unit select component changes */
  buCertificateSelectionChange(event: { value: string }) {
    this.selectedBU = event.value;
  }

  downloadMyCertificate(form: FormGroup) {
    let message = "";
    this.selectedDonationCertificate = this.donationCertificates.filter(
      (donationCertificate) => {
        return (
          donationCertificate.year === this.selectedYear &&
          donationCertificate.bu_code === this.selectedBU
        );
      }
    )[0];
    this.getBusinessUnit();
  }

  getBusinessUnit() {
    this.isLoading = true;
    this.businessunitService.getOne(this.selectedBU).subscribe(
      (p) => {
        this.businessUnits = p.results !== "" ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.businessUnits.length > 0) {
          this.selectedBusinessUnitCertificate = this.businessUnits[0];
          this.buildCertificateContent();
          this.getReport();
        }
      }
    );
  }

  buildCertificateContent() {
    moment.locale("es");
    const donorFirstName =
      this.selectedDonationCertificate.firstname !== ""
        ? this.selectedDonationCertificate.firstname.toUpperCase().trim() + " "
        : "";
    const donorMiddleName = this.selectedDonationCertificate.middlename
      ? this.selectedDonationCertificate.middlename.toUpperCase().trim() + " "
      : "";
    const donorLastName = this.selectedDonationCertificate.first_lastname
      ? this.selectedDonationCertificate.first_lastname.toUpperCase().trim() +
        " "
      : "";
    const donorSecondLastName = this.selectedDonationCertificate.second_lastname
      ? this.selectedDonationCertificate.second_lastname.toUpperCase().trim()
      : "";
    const donorName =
      this.selectedDonationCertificate.firstname !== ""
        ? donorFirstName + donorMiddleName + donorLastName + donorSecondLastName
        : this.selectedDonationCertificate.org_name.toUpperCase().trim();
    this.letterTitle =
      this.selectedBusinessUnitCertificate.id === "C11" ||
      this.selectedBusinessUnitCertificate.id === "U11"
        ? "EL CONTADOR GENERAL DE IGLESIA ADVENTISTA DEL SEPTIMO DIA DE COLOMBIA " +
          " CERTIFICA:"
        : "EL CONTADOR GENERAL DE IGLESIA ADVENTISTA DEL SEPTIMO DIA – SECCION " +
          this.selectedBusinessUnitCertificate.name.toUpperCase() +
          " CERTIFICA:";
    this.letterOne =
      this.selectedBusinessUnitCertificate.id === "C11" ||
      this.selectedBusinessUnitCertificate.id === "U11"
        ? "1. Que la IGLESIA ADVENTISTA DEL SEPTIMO DIA DE COLOMBIA" +
          ", es una entidad religiosa sin ánimo de lucro inscrita en el Ministerio del " +
          "Interior y Justicia con Personería Jurídica Especial según " +
          "resolución No. " +
          this.selectedBusinessUnitCertificate.resolution +
          ", NIT " +
          this.selectedBusinessUnitCertificate.national_code +
          " con domicilio en la ciudad de " +
          this.selectedBusinessUnitCertificate.location +
          "."
        : "1. Que la IGLESIA ADVENTISTA DEL SEPTIMO DIA – SECCION " +
          this.selectedBusinessUnitCertificate.name.toUpperCase() +
          ", es una entidad religiosa sin ánimo de lucro inscrita en el Ministerio del " +
          "Interior y Justicia con Personería Jurídica Especial según " +
          "resolución No. " +
          this.selectedBusinessUnitCertificate.resolution +
          ", NIT " +
          this.selectedBusinessUnitCertificate.national_code +
          " con domicilio en la ciudad de " +
          this.selectedBusinessUnitCertificate.location +
          ".";
    this.letterTwo =
      "2. Que esta entidad ha presentado las declaraciones de Ingresos y " +
      "Patrimonio en forma oportuna y sin interrupcion.";
    this.letterThree =
      "3. Que la entidad maneja mediante cuentas de ahorros " +
      "y corriente debidamente autorizados por la Superintendencia Bancaria " +
      "todos los ingresos por donaciones como los gastos propios de su actividad.";
    this.letterFour =
      "4. Que durante el año " +
      this.selectedYear +
      " la entidad recibió de " +
      donorName +
      ", identificado con el documento No. " +
      this.selectedDonationCertificate.donor_dni +
      " la suma de:";
    this.letterFive =
      "5. Que esta donación fue por concepto de diezmos y ofrendas";
    this.letterSix =
      "6. Que todos los ingresos recibidos como donaciones " +
      "son invertidos en el desarrollo de su objeto social dentro del terriorio " +
      "Nacional.";
    this.letterArticles =
      "Se expide para fines relacionados con los artículos " +
      "125, 125-1, 125-2, 125-3 del Estatuto Tributario.";
    this.letterDate =
      "En constancia se firma el " +
      this.selectedDonationCertificate.date +
      " en la ciudad de " +
      this.selectedBusinessUnitCertificate.location;
  }

  getCommaSeparatedTwoDecimalsNumber(number) {
    if (number < 0 && number > -0.001) {
      number = Math.abs(number);
    }
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getReport() {
    const donorFirstName =
      this.selectedDonationCertificate.firstname !== ""
        ? this.selectedDonationCertificate.firstname.toUpperCase().trim() + " "
        : "";
    const donorMiddleName = this.selectedDonationCertificate.middlename
      ? this.selectedDonationCertificate.middlename.toUpperCase().trim() + " "
      : "";
    const donorLastName = this.selectedDonationCertificate.first_lastname
      ? this.selectedDonationCertificate.first_lastname.toUpperCase().trim() +
        " "
      : "";
    const donorSecondLastName = this.selectedDonationCertificate.second_lastname
      ? this.selectedDonationCertificate.second_lastname.toUpperCase().trim()
      : "";
    const donorName =
      this.selectedDonationCertificate.firstname !== ""
        ? donorFirstName + donorMiddleName + donorLastName + donorSecondLastName
        : this.selectedDonationCertificate.org_name.toUpperCase().trim();
    var doc = new jsPDF("p", "mm", "a4");
    const pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const margin = 5;
    const rowMargin = 7;
    const lMargin = 20; //left margin in mm
    const rMargin = 30; //right margin in mm
    const pageCenter = pageWidth / 2;
    // From Javascript
    var img = new Image();
    img.src = "assets/icon_text_green_min.png";
    doc.addImage(img, "png", lMargin, 19, 55, 14);
    doc.setFontSize(8);
    doc.setFont("helvetica", "", "normal");
    doc.setTextColor(62, 131, 145);
    doc.text(
      this.selectedBusinessUnitCertificate.name.toUpperCase(),
      lMargin,
      7.5 * margin,
      { align: "left" }
    );
    // bu information
    doc.text(
      "NIT: " + this.selectedBusinessUnitCertificate.national_code,
      lMargin * 7,
      4.5 * margin,
      { align: "left" }
    );
    doc.text(
      this.selectedBusinessUnitCertificate.address,
      lMargin * 7,
      5.1 * margin,
      { align: "left" }
    );
    doc.text(
      "Tel: " + this.selectedBusinessUnitCertificate.phone,
      lMargin * 7,
      5.7 * margin,
      { align: "left" }
    );
    doc.text(
      this.selectedBusinessUnitCertificate.location,
      lMargin * 7,
      6.3 * margin,
      { align: "left" }
    );
    doc.text(
      this.selectedBusinessUnitCertificate.website == null
        ? ""
        : this.selectedBusinessUnitCertificate.website,
      lMargin * 7,
      6.9 * margin,
      { align: "left" }
    );

    // right icon
    doc.setDrawColor(62, 131, 145);
    doc.line(pageWidth - 25, 15, pageWidth - 25, pageHeight - 15);
    var img = new Image();
    img.src = "assets/simbolo_green_min.png";
    doc.addImage(img, "png", pageWidth - 20, 19, 14, 14);
    doc.setFontSize(12);
    doc.setFont("helvetica", "", "bold");
    doc.setTextColor(62, 131, 145);
    doc.text("CERTIFICADO DE DONACIÓN", pageCenter, 13 * margin, {
      align: "center",
    });
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    var lines = doc.splitTextToSize(
      this.letterTitle,
      pageWidth - lMargin - rMargin
    );
    doc.text(lines, pageCenter, 16 * margin, { align: "center" });
    doc.setFont("helvetica", "", "normal");
    lines = doc.splitTextToSize(this.letterOne, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 19 * margin);
    lines = doc.splitTextToSize(this.letterTwo, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 23 * margin);
    lines = doc.splitTextToSize(
      this.letterThree,
      pageWidth - lMargin - rMargin
    );
    doc.text(lines, lMargin, 25 * margin, { maxWidth: 157, align: "justify" });
    lines = doc.splitTextToSize(this.letterFour, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 28 * margin);
    doc.setFont("helvetica", "", "bold");
    lines = doc.splitTextToSize(
      this.getCommaSeparatedTwoDecimalsNumber(
        this.selectedDonationCertificate.amount
      ),
      pageWidth - lMargin - rMargin
    );
    doc.setFont("helvetica", "", "bold");
    doc.text("$ " + lines, pageCenter, 31 * margin, { align: "center" });
    doc.setFont("helvetica", "", "normal");
    lines = doc.splitTextToSize(this.letterFive, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 33 * margin, { maxWidth: 157, align: "justify" });
    lines = doc.splitTextToSize(this.letterSix, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 34 * margin, { maxWidth: 157, align: "justify" });
    lines = doc.splitTextToSize(
      this.letterArticles,
      pageWidth - lMargin - rMargin
    );
    doc.text(lines, lMargin, 37 * margin, { maxWidth: 157, align: "justify" });
    lines = doc.splitTextToSize(this.letterDate, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 39 * margin, { maxWidth: 157, align: "justify" });
    //signature
    var img = new Image();
    img.crossOrigin = "";
    img.src =
      GlobalVariable.BASE_BLOBSTORAGE_URL +
      GlobalVariable.SIGNATURE_BLOBCONTAINER_NAME +
      "/" +
      this.selectedBusinessUnitCertificate.id +
      "/" +
      "accountant.png";
    try {
      doc.addImage(img, "png", lMargin, pageHeight - 85, 65, 16);
    } catch (error) {
      console.log(error);
    }
    doc.setFont("helvetica", "", "bold");
    doc.text(
      this.selectedBusinessUnitCertificate.accountant.toUpperCase(),
      lMargin,
      pageHeight - 68,
      { align: "left" }
    );
    doc.text("Contador Público", lMargin, pageHeight - 65, { align: "left" });
    doc.text(
      this.selectedBusinessUnitCertificate.accountant_code == null
        ? ""
        : "Tarjeta profesional: " +
            this.selectedBusinessUnitCertificate.accountant_code,
      lMargin,
      pageHeight - 62,
      { align: "left" }
    );
    doc.setFontSize(6);
    doc.setFont("helvetica", "", "normal");
    doc.text(
      "CERTIFICADO GENERADO POR " +
        donorName +
        " EL " +
        moment().format("DD/MM/YYYY"),
      lMargin,
      pageHeight - 10,
      {
        align: "left",
      }
    );
    doc.save("CERTIFICADO_DONACION_" + this.selectedYear + ".pdf");
  }

  /** Launch message of the snackBar component */
  launchMessage(message: string) {
    this.errorMessage = "";
    this.isLoading = false;
    const action = "OK";
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
