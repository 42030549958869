import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material"
import { EpaycoPayment } from "src/app/model/epaycoPayment"
import { WompiPayment } from "src/app/model/wompiPayment"

@Component({
  selector: "app-paymentinfo-dialog",
  templateUrl: "./paymentinfo-dialog.component.html",
  styleUrls: ["./paymentinfo-dialog.component.scss"],
})
export class PaymentinfoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PaymentinfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public paymentInformation: WompiPayment
  ) {}

  onNoClick(): void {
    this.dialogRef.close()
  }
}
