import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Donor } from "src/app/model/donor";
import { DonorService } from "src/app/service/donor.service";

@Component({
  selector: "app-validatecode",
  templateUrl: "./validatecode.component.html",
  styleUrls: ["./validatecode.component.scss"],
})
export class ValidatecodeComponent implements OnInit {
  AppName = "";
  isLoading = false;
  errorMessage = "";
  type = "message";
  verificationStatus = "";
  validationCode = "";
  constructor(
    private route: ActivatedRoute,
    private donorService: DonorService
  ) {}

  ngOnInit() {
    this.getParams();
  }

  getParams() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("c")) {
        this.validationCode = params.get("c");
        this.type = "validate";
        this.validateCode();
      } else {
        this.type = "message";
      }
    });
  }

  validateCode() {
    let response = "";
    this.donorService.getValidateUserCode(this.validationCode).subscribe(
      (p) => (response = p.message),
      (e) => console.log(e),
      () => {
        this.isLoading = false;
        if (response == "Validated.") {
          this.verificationStatus =
            "Tu cuenta ha sido verificada exitosamente. Ya puedes iniciar sesión.";
        } else {
          this.verificationStatus =
            "El código ingresado no existe. No ha sido posible verificar tu cuenta.";
        }
      }
    );
  }
}
