import { Component, OnInit } from "@angular/core"
import { GlobalVariable } from "src/app/config/global"

@Component({
  selector: "app-topmenu-help",
  templateUrl: "./topmenu-help.component.html",
  styleUrls: ["./topmenu-help.component.scss"],
})
export class TopmenuHelpComponent implements OnInit {
  AppName = ""
  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME
  }

  playPause() {
    let audio = <HTMLMediaElement>document.getElementById("player")
    if (audio.paused && audio.currentTime > 0 && !audio.ended) {
      audio.play()
    } else {
      audio.pause()
    }
  }
}
