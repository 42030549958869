import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router"
import { MatSnackBar, MatDialog } from "@angular/material"
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms"
import { BusinessUnit } from "src/app/model/businessUnit"
import { BusinessunitService } from "src/app/service/businessunit.service"
import { District } from "src/app/model/district"
import { DistrictService } from "src/app/service/district.service"
import { Church } from "src/app/model/church"
import { ChurchService } from "src/app/service/church.service"
import { Union } from "src/app/model/union"
import { UnionService } from "src/app/service/union.service"
import * as password from "secure-random-password"



@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  isLoading = false
  btnSend = false
  errorMessage = ""
  contactForm: FormGroup
  @ViewChild('inputFile', {static: false}) inputF: ElementRef;
  // params
  unionParameter
  businessUnitParameter
  districtParameter
  churchParameter
  // model
  unions: Union[] = []
  selectedUnion = <Union>{
    id: "",
  }
  businessUnits: BusinessUnit[] = []
  selectedBusinessUnit = <BusinessUnit>{
    id: "",
  }
  districts: District[]
  selectedDistrict = <District>{
    id: "",
  }
  churchs: Church[]
  selectedChurch = <Church>{
    id: "",
  }
  selectedFile = {
    name: null,
    base64textString: null
  }

  constructor(
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private unionService: UnionService,
    private businessunitService: BusinessunitService,
    private districtService: DistrictService,
    private churchService: ChurchService,
  ) {}

  ngOnInit() { 
    this.initForms()
    this.getParams()
    this.getAllUnion()
  }

  initForms() {
    this.contactForm = this.formBuilder.group({
      firstName: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      phoneNumber: new FormControl("", [Validators.required]),
      union: new FormControl("", [Validators.required]),
      bu: new FormControl("", [Validators.required]),
      district: new FormControl(),
      church: new FormControl(),
      message: new FormControl("", [Validators.required]), 
      privacy: new FormControl(false, [Validators.requiredTrue]),
    })
  }
  
  getErrorMessage(component: string) {
    let errorMessage = ""
    switch (component) {
      case "firstName":
        errorMessage = this.contactForm.get("firstName").hasError("required")
          ? "debe ingresar su nombre"
          : ""
        break
      case "email":
        errorMessage = this.contactForm.get("email").hasError("required")
          ? "campo requerido"
          : this.contactForm.get("email").hasError("email")
          ? "ingresa un correo válido"
          : ""
        break
      case "phoneNumber":
        errorMessage = this.contactForm.get("phoneNumber").hasError("required")
          ? "debe ingresar su número de teléfono"
          : ""
        break
      case "union":
        errorMessage = this.contactForm.get("union").hasError("required")
          ? "debe seleccionar su unión"
          : ""
        break
      case "bu":
        errorMessage = this.contactForm.get("bu").hasError("required")
          ? "debe seleccionar su campo"
          : ""
        break
      case "message":
        errorMessage = this.contactForm.get("message").hasError("required")
          ? "debe ingresar su mensaje"
          : ""
        break
      case "privacy":
      errorMessage = this.contactForm.get("privacy").hasError("required")
        ? "Debe aceptar términos y condiciones para poder continuar"
        : ""
      break
    }
    return errorMessage
  }

  getParams() {
    this.route.paramMap.subscribe((params) => {
      this.unionParameter = params.get("u")
      this.businessUnitParameter = params.get("bu")
      this.districtParameter = params.get("d")
      this.churchParameter = params.get("c")
    })
  }

  setPredefinedParams(type: string) {
    switch (type) {
      case "union":
        this.selectedUnion = this.unions.find(
          (u) => u.id.trim() === this.unionParameter
        )
        if (this.selectedUnion) {
          this.contactForm.controls["union"].setValue(this.selectedUnion)
          this.contactForm.controls["union"].disable()
          this.getBusinessUnitByUnion()
        }
        break
      case "businessunit":
        this.selectedBusinessUnit = this.businessUnits.find(
          (bu) => bu.id.trim() === this.businessUnitParameter
        )
        if (this.selectedBusinessUnit) {
          this.contactForm.controls["bu"].setValue(this.selectedBusinessUnit)
          this.contactForm.controls["bu"].disable()
          this.getDistricsByBU()
        }
        break
      case "district":
        this.selectedDistrict = this.districts.find(
          (d) => d.code.trim() === this.districtParameter
        )
        if (this.selectedDistrict) {
          this.contactForm.controls["district"].setValue(this.selectedDistrict)
          this.contactForm.controls["district"].disable()
          this.getChurchsByDistrict()
        }
        break
      case "church":
        this.selectedChurch = this.churchs.find(
          (c) => c.code.trim() === this.churchParameter
        )
        if (this.selectedChurch) {
          this.contactForm.controls["church"].setValue(this.selectedChurch)
          this.contactForm.controls["church"].disable()
        }
        break
      default:
        break
    }
  }

  getAllUnion() {
    this.isLoading = true
    this.unionService.get().subscribe(
      (p) => {
        this.unions = p.results !== undefined ? p.results : []
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false
        if (this.unions.length > 0 && this.unionParameter) {
          this.setPredefinedParams("union")
        }
      }
    )
  }

  /** Event handler when business unit select component changes */
  unionSelectionChange(event: { value: Union }) {
    this.selectedUnion = event.value
    this.contactForm.controls["bu"].setValue(null)
    this.contactForm.controls["district"].setValue(null)
    this.contactForm.controls["church"].setValue(null)
    this.getBusinessUnitByUnion()
  }

  getBusinessUnitByUnion() {
    this.isLoading = true
    this.businessunitService.getByUnion(this.selectedUnion.id).subscribe(
      (p) => {
        this.businessUnits = p.results !== undefined ? p.results : []
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false
        if (this.businessUnits.length > 0 && this.businessUnitParameter) {
          this.setPredefinedParams("businessunit")
        }
      }
    )
  }

  /** Event handler when business unit select component changes */
  buSelectionChange(event: { value: BusinessUnit }) {
    this.selectedBusinessUnit = event.value
    this.contactForm.controls["district"].setValue(null)
    this.contactForm.controls["church"].setValue(null)
    this.getDistricsByBU()
  }

  getDistricsByBU() {
    this.isLoading = true
    this.districtService.getByBU(this.selectedBusinessUnit.id).subscribe(
      (p) => {
        this.districts = p.results !== undefined ? p.results : []
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false
        if (this.districts.length > 0 && this.districtParameter) {
          this.setPredefinedParams("district")
        }
      }
    )
  }

  /** Event handler when district select component changes */
  districtSelectionChange(event: { value: District }) {
    this.selectedDistrict = event.value
    this.contactForm.controls["church"].setValue(null)
    this.getChurchsByDistrict()
  }

  getChurchsByDistrict() {
    this.isLoading = true
    this.churchService.getByDistrict(this.selectedDistrict.id).subscribe(
      (p) => {
        this.churchs = p.results !== undefined ? p.results : []
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false
        if (this.churchs.length > 0 && this.churchParameter) {
          this.setPredefinedParams("church")
        }
      }
    )
  }

  /* attached file */
  selectFile(event) {
    var files = event.target.files;
    var file = files[0];
    if(files && file) {
      this.selectedFile.name = file.name;
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  /* attached file */
  _handleReaderLoaded(readerEvent) {
    var binaryString = readerEvent.target.result;
    this.selectedFile.base64textString = btoa(binaryString);
  }

  /* attached file */
  upload() {
    this.businessunitService.uploadFile(this.selectedFile).subscribe(
      (p) => {
      },
      (e) => this.launchMessage(e),
      () => {
      }
    );
  }

  saveData(form: FormGroup) {
    if (form.valid) {
      this.isLoading = true
      this.upload()   
      this.btnSend = true
      let message
      let w = this.buildJSONtoEmail(this.contactForm.value)
      this.businessunitService.sendEmailSupport(w).subscribe(
        (p) => (message = p.message),
        (e) => console.log("Error"),
        () => {
          if (message === "Email was sent") {
            this.launchMessage("Gracias por contactarte con nosotros.");
            this.btnSend = false
          } else {
            console.log("error, intentalo mas tarde")
            this.btnSend = false
          }
          this.isLoading = false
        }
      )

      form.reset();
      this.inputF.nativeElement.value = '';
      this.selectedFile = {
        name: null,
        base64textString: null
      }
      this.businessUnits =[]
      this.districts = []
      this.churchs = []
  
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName("ng-invalid")
      )
      if (invalidFields.length > 2) {
        invalidFields[2].focus()
      } else {
        invalidFields[1].focus()
      }
    }
  }

  buildJSONtoEmail(form){
    let unionEmail
    if (form.union.short_name == "Union Colombiana del Sur") {
      unionEmail = "sistemas@unioncolombianadelsur.org"
    }
    if (form.union.short_name == "Union Colombiana del Norte") {
      unionEmail = "sistemas@iasd.org.co"
    }
    const x = {
      support_id: password.randomPassword({
        length: 4,
        characters: [password.upper, password.digits],
      }),
      support_user_name: form.firstName,
      support_user_email: form.email,
      support_user_phoneNumber: form.phoneNumber,
      support_user_message: form.message,
      support_union_email: unionEmail,
      support_bu_email: form.bu.email,
      support_bu_code: form.bu.id,
      support_church_name: form.church ? form.church.name: "",
      support_district_name: form.district ? form.district.name: "",
      support_file: this.selectedFile.name ? this.selectedFile.name: "",
    }
    return x
  }
  
  /** Event handler when church select component changes */
  churchSelectionChange(event: { value: Church }) {
    this.selectedChurch = event.value
  }

  /** Launch message of the snackBar component */
   launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 5000,
    })
  }
}
