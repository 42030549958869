import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import * as SecureLS from "secure-ls";
import { MatSnackBar } from "@angular/material";
import { GlobalVariable } from "src/app/config/global";
import { Donor } from "src/app/model/donor";
import { DonorService } from "src/app/service/donor.service";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  AppName = GlobalVariable.APP_NAME;
  isLoading = false;
  errorMessage = "";
  loginForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private donorService: DonorService
  ) {}

  ngOnInit() {
    this.initForms();
  }

  initForms() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
    });
  }

  getErrorMessage(component: string) {
    let errorMessage = "";
    switch (component) {
      case "email":
        errorMessage = this.loginForm.get("email").hasError("required")
          ? "campo requerido"
          : this.loginForm.get("email").hasError("email")
          ? "ingresa un correo válido"
          : "";
        break;
      case "password":
        errorMessage = this.loginForm.get("password").hasError("required")
          ? "debe ingresar su contraseña"
          : "";
        break;
    }
    return errorMessage;
  }

  validateCredentials(form: FormGroup) {
    let donor: Donor;
    this.donorService
      .getValidateUser(form.value.email, form.value.password)
      .subscribe(
        (p) => {
          donor = p.results !== undefined ? p.results[0] : null;
        },
        (e) => console.log(e),
        () => {
          this.isLoading = false;
          if (donor) {
            this.setUser(donor);
            this.play();
            this.router.navigate(["/myenvelope"]);
          } else {
            this.launchMessage("Credenciales incorrectas. Intenta nuevamente.");
            this.loginForm.get("password").reset();
          }
        }
      );
  }

  setUser(donor: Donor) {
    var ls = new SecureLS({ encodingType: "aes" });
    ls.set("auid", donor.id);
    this.loginForm.reset();
  }

  play() {
    let audio = <HTMLMediaElement>document.getElementById("player");
    audio.src = "https://alfoliadventista.org/assets/522pista.mp3";
    audio.load();
    audio.play();
  }

  /** Launch message of the snackBar component */
  launchMessage(message: string) {
    this.errorMessage = "";
    this.isLoading = false;
    const action = "OK";
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
