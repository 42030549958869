import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-paymentsecurity",
  templateUrl: "./paymentsecurity.component.html",
  styleUrls: ["./paymentsecurity.component.scss"],
})
export class PaymentsecurityComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  verifySeal() {
    var bgHeight = "null"
    var bgWidth = "393"
    var url =
      "https://seal.godaddy.com/verifySeal?sealID=0bvYUzmcYSoEC6NTSDoWuoRkWor2NpSs5Y2zef7dVELaiRuklFQMGouEeIkF"
    window.open(
      url,
      "SealVerfication",
      "menubar=no,toolbar=no,personalbar=no,location=yes,status=no,resizable=yes,fullscreen=no,scrollbars=no,width=" +
        bgWidth +
        ",height=" +
        bgHeight
    )
  }
}
